.slack-redirect-handler {
    background: #4b4e55;
    font-family: "Source Sans Pro", sans-serif;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img.logo {
        padding: 20px;
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: pointer;
        width: 200px;
    }

    div.content-container {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }

    h1,
    h2 {
        color: #ffffff;
        margin: 0 0 40px 0;
        max-width: 70%;
        text-align: center;
    }

    button.go-to-bai-button {
        background: #11a9e2;
        color: #fff;
        font-family: Source Sans Pro;
        font-weight: 600;
        font-size: 15px;
        font-weight: 600;
        padding: 7px 20px;
        vertical-align: middle;
        display: inline-block;
        min-width: 100px;
        height: 44px;
        text-align: center;
        outline: none;
        border: 1px solid #23aae0;
        text-transform: uppercase;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        letter-spacing: 0.05em;
    }

    .spinner {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }

    .spinner .circular {
        animation: rotate 2s linear infinite;
        width: 40px;
        height: 40px;
        transform-origin: center center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .spinner .path {
        stroke: #23aae0;
        fill: none;
        stroke-width: 4px;
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke-miterlimit: 10;
        animation: dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
    }
}
